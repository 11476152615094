<template>
  <div
    v-if="title"
    class="flex justify-between items-center"
  >
    <app-heading>
      {{ title }}
    </app-heading>
  </div>

  <template v-if="!loading">
    <app-card-list
      v-if="partners.length > 0"
      :list="partners"
      :component="PartnerHorizontalCard"
    />

    <p
      v-else
      class="mt-6 text-center"
    >
      {{ t('ttmt.partners.index.no_results') }}
    </p>

    <template v-if="lastPage && page < lastPage">
      <a
        v-if="nextPageFetching === false"
        href="#"
        class="block text-center app-link w-full"
        @click.prevent="nextPage"
      >
        {{ t('ttmt.common.actions.load_more') }}
      </a>
    </template>

    <div
      v-if="nextPageFetching"
      class="text-center w-full"
    >
      <app-spinner
        class="text-theme-500"
        size="lg"
      />
    </div>
  </template>

  <template v-else>
    <partner-horizontal-card-empty
      v-for="i in 5"
      :key="i"
      class="my-4"
    />
  </template>
</template>

<script setup>
import { useI18n } from 'vue-i18n'
import { onMounted, ref } from 'vue'

import { fetchPartners as fetchApiPartners } from '@shared/http/api'
import useAuthUser from '@shared/hooks/auth/authUser'
import AppHeading from '@app/components/ui/AppHeading.vue'
import AppCardList from '@shared/components/ui/card/AppCardList.vue'
import AppSpinner from '@shared/components/ui/AppSpinner.vue'
import PartnerHorizontalCard from '@app/components/resources/partner/PartnerHorizontalCard.vue'
import PartnerHorizontalCardEmpty from '@app/components/resources/partner/PartnerHorizontalCardEmpty.vue'

const props = defineProps({
  // Page title
  title: {
    type: String,
    default: null,
  },
  // Partner type
  type: {
    type: String,
    required: true,
    validator(value) {
      return [
        'partner',
        'customer',
      ].includes(value)
    },
  },
})

const { t } = useI18n()
const { countryCode } = useAuthUser()

const partners = ref([])
const loading = ref(false)

function fetchPartners(nextPage = false) {
  if (nextPage === false) {
    loading.value = true
  }

  nextPageFetching.value = nextPage

  const params = {
    'type': props.type,
    'mode': 'app',
    'page': page.value,
    'countryCode': countryCode.value,
  }

  fetchApiPartners(params)
    .then((response) => {
      const oldPartners = partners.value
      const newPartners = response.data.data?.map((partner) => ({
        resource: partner,
      })) ?? []

      if (nextPage) {
        // add new results after the old ones
        partners.value = [...oldPartners, ...newPartners]
      } else {
        // replace the old results with the new ones
        partners.value = newPartners
      }

      lastPage.value = response.data.meta?.last_page
    })
    .finally(() => {
      nextPageFetching.value = false
      loading.value = false
    })
}

// ---------- PAGINATION ----------

const nextPageFetching = ref(false)
const page = ref(1)
const lastPage = ref(null)

function nextPage() {
  page.value += 1
  fetchPartners(true)
}

// ---------- LIFECYCLES ----------

onMounted(() => (
  fetchPartners()
))
</script>
